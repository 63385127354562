import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';

import testSlice from 'src/slices/testSlice';
import dateFilter from 'src/slices/dateFilter';
import yearFilter from 'src/slices/yearFilter';
import multiDateFilter from 'src/slices/multiDateFilter';
import compareByLocations from 'src/slices/compareByLocations';


const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  
  testSlice: testSlice.reducer,
  dateFilter: dateFilter.reducer,
  yearFilter: yearFilter.reducer,
  multiDateFilter: multiDateFilter.reducer,
  compareByLocations: compareByLocations.reducer,
});


export default rootReducer;
