export const DateFilter = {
    NONE: 0,
    TODAY: 1,
	THIS_MONTH: 2,
    THIS_YEAR: 3,
}

export const InOutTotal = {
    NONE: 0,
    IN: 1,
	OUT: 2,
    TOTAL: 3,
}