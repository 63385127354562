/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

import Utils from '../utils/Utils';
import { DateFilter } from '../utils/Enums';


function NewReducer() {
    const name = 'compareByLocations';


    const initialState = {
        selectedGradId: '',
        selectedGradText: '',
        isRegion: false,

        selectedRegionId: '',
        selectedRegionText: '',
        
        selectedAddressIds: [],
        selectedAddressTexts: [],
        selectedAddressText: '',
    };


    const reducers = {
        setSelectedGrad: (state, action) => {
            let data = action.payload;

            state.selectedGradId = data.value;
            state.selectedGradText = data.text;
            state.isRegion = data.isRegion;

            state.selectedRegionId = '';
            state.selectedRegionText = '';

            state.selectedAddressIds = [];
            state.selectedAddressTexts = [];
            state.selectedAddressText = '';
        },

        setSelectedRegion: (state, action) => {
            let data = action.payload;

            state.selectedRegionId = data.value;
            state.selectedRegionText = data.text;

            state.selectedRegionIds = [];
            state.selectedAddressTexts = [];
            state.selectedAddressText = '';
        },

        setSelectedAddress: (state, action) => {
            let data = action.payload;

            state.selectedAddressIds = data.value;
            state.selectedAddressTexts = data.text;
            state.selectedAddressText = data.str;
        },
    };


    const { reducer, actions } = createSlice({
        name,
        initialState,
        reducers,
    });


    return {
        reducer,
        ...actions,
    };
}


export default NewReducer();