/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";
import dayjs from 'dayjs';

import Utils from '../utils/Utils';


function NewReducer() {
    const name = 'multiDateFilter';


    const today = new Date();
    const initialState = {
        isLoading: false,
        values: [
            (new Date().setDate(today.getDate()-4)),
            (new Date().setDate(today.getDate()-3)),
            (new Date().setDate(today.getDate()-2)),
            (new Date().setDate(today.getDate()-1)),
            today
        ],
    };


    const reducers = {
        start: (state, action) => {
            let data = action.payload;
        
            state.isLoading = true;
            state.values = data;
        },
        finish: (state, action) => {
            state.isLoading = false;
        },
    };


    const apis = {
        setValues: (items = []) => async (dispatch) => {
            let dates = items.sort((a, b) => {
                return dayjs(a).isAfter(dayjs(b)) ? 1 : -1
            });

            dispatch(actions.start(dates));
            
            await setTimeout(() => {
                dispatch(actions.finish());
            }, 1000);
        },
    };


    const { reducer, actions } = createSlice({
        name,
        initialState,
        reducers,
    });


    return {
        reducer,
        ...actions,
        ...apis,
    };
}


export default NewReducer();