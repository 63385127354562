import { Suspense, lazy } from 'react';
import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';

import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import ExtendedSidebarLayout from 'src/layouts/ExtendedSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';

import dashboardsRoutes from './dashboards';
import blocksRoutes from './blocks';
import applicationsRoutes from './applications';
import managementRoutes from './management';
import documentationRoutes from './documentation';
import accountRoutes from './account';
import baseRoutes from './base';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Reports = Loader(
  lazy(() => import('src/content/dashboards/Reports'))
);
const Statistics = Loader(
  lazy(() => import('src/content/dashboards/Statistics'))
);
const Finance = Loader(
  lazy(() => import('src/content/dashboards/Finance'))
);
const Fitness = Loader(
  lazy(() => import('src/content/dashboards/Fitness'))
);
const CompareByDates = Loader(
  lazy(() => import('src/content/dashboards/CompareByDates'))
);

const router = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },

  // Documentation

  {
    path: 'docs',
    element: <DocsLayout />,
    children: documentationRoutes
  },

  // Boxed Sidebar Layout

  {
    path: 'boxed-sidebar',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Accent Sidebar Layout

  {
    path: 'accent-sidebar',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Collapsed Sidebar Layout

  {
    path: 'collapsed-sidebar',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Bottom Navigation Layout

  {
    path: 'bottom-navigation',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Top Navigation Layout

  {
    path: 'top-navigation',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Accent Header Layout

  {
    path: 'accent-header',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      {
        path: 'blocks',
        children: blocksRoutes
      },
      {
        path: 'applications',
        children: applicationsRoutes
      },
      {
        path: 'management',
        children: managementRoutes
      }
    ]
  },

  // Extended Sidebar Layout

  {
    path: '',
    element: (
      <Authenticated>
        <ExtendedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="dashboards" replace />
      },
      {
        path: 'dashboards',
        children: dashboardsRoutes
      },
      
      {
        path: 'gradovi',
        element: <Reports />,
      },
      {
        path: 'grad',
        element: <Reports />,
        children: [
          {
            path: ':gradId',
            children: [
              
              {
                path: 'region',
                children: [
                  {
                    path: ':regionId',
                    children: [

                      {
                        path: 'adresa',
                        children: [
                          {
                            path: ':adresaId',
                          },
                        ]
                      },

                    ]
                  },
                ]
              },
              
              {
                path: 'adresa',
                children: [
                  {
                    path: ':adresaId',
                  },
                ]
              },
            ]
          },
          
        ]
      },

      {
        path: 'izvestaj',
        element: <Fitness />,
        children: [
          {
            path: 'grad',
            children: [
              {
                path: ':gradId',
                children: [
                  
                  {
                    path: 'region',
                    children: [
                      {
                        path: ':regionId',
                        children: [

                          {
                            path: 'adresa',
                            children: [
                              {
                                path: ':adresaId',
                                children: [
                                  {
                                    path: 'izvestaj',
                                    children: [
                                      {
                                        path: 'in',
                                        children: [
                                          {
                                            path: ':izvestajId',
                                          },
                                        ]
                                      },
                                      {
                                        path: 'out',
                                        children: [
                                          {
                                            path: ':izvestajId',
                                          },
                                        ]
                                      },
                                      {
                                        path: 'total',
                                        children: [
                                          {
                                            path: ':izvestajId',
                                          },
                                        ]
                                      },
                                    ]
                                  },
                                ]
                              },
                            ]
                          },

                          {
                            path: 'izvestaj',
                            children: [
                              {
                                path: 'in',
                                children: [
                                  {
                                    path: ':izvestajId',
                                  },
                                ]
                              },
                              {
                                path: 'out',
                                children: [
                                  {
                                    path: ':izvestajId',
                                  },
                                ]
                              },
                              {
                                path: 'total',
                                children: [
                                  {
                                    path: ':izvestajId',
                                  },
                                ]
                              },
                            ]
                          },

                        ]
                      },
                    ]
                  },
                  
                  {
                    path: 'adresa',
                    children: [
                      {
                        path: ':adresaId',
                        children: [
                          {
                            path: 'izvestaj',
                            children: [
                              {
                                path: 'in',
                                children: [
                                  {
                                    path: ':izvestajId',
                                  },
                                ]
                              },
                              {
                                path: 'out',
                                children: [
                                  {
                                    path: ':izvestajId',
                                  },
                                ]
                              },
                              {
                                path: 'total',
                                children: [
                                  {
                                    path: ':izvestajId',
                                  },
                                ]
                              },
                            ]
                          },
                        ]
                      },
                    ]
                  },
                ]
              },
              
            ]
          },
        ]
      },


      {
        path: 'compare-by-locations',
        element: <Statistics />,
      },
      {
        path: 'compare-by-days',
        element: <CompareByDates />,
      },
      {
        path: 'statistics-by-year',
        element: <Finance />,
      },

    ]
  },
    
];

export default router;
