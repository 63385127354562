/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

import Utils from '../utils/Utils';
import { DateFilter } from '../utils/Enums';


function NewReducer() {
    const name = 'dateFilter';


    const initialState = {
        isLoading: false,
        selectedValue: DateFilter.TODAY,
        selectedText: 'Today',
        items: [
            {
                value: DateFilter.TODAY,
                text: 'Today'
              },
              {
                value: DateFilter.THIS_MONTH,
                text: 'This Month'
              },
              {
                value: DateFilter.THIS_YEAR,
                text: 'This Year'
              },
        ],
    };


    const reducers = {
        start: (state, action) => {
            let data = action.payload;
        
            state.isLoading = true;
            state.selectedValue = data.value;
            state.selectedText = data.text;
        },
        finish: (state, action) => {
            state.isLoading = false;
        },
    };


    const apis = {
        setSelected: (selectedItem = null) => async (dispatch) => {
            dispatch(actions.start(selectedItem));
            
            await setTimeout(() => {
                dispatch(actions.finish());
            }, 1500);
        },
    };


    const { reducer, actions } = createSlice({
        name,
        initialState,
        reducers,
    });


    return {
        reducer,
        ...actions,
        ...apis,
    };
}


export default NewReducer();