/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import DashboardIcon from '@mui/icons-material/Dashboard';
// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
// import HealthAndSafetyTwoToneIcon from '@mui/icons-material/HealthAndSafetyTwoTone';
// import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
// import StorefrontTwoToneIcon from '@mui/icons-material/StorefrontTwoTone';
// import VpnKeyTwoToneIcon from '@mui/icons-material/VpnKeyTwoTone';
// import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
// import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
// import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone';
// import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';
// import BackupTableTwoToneIcon from '@mui/icons-material/BackupTableTwoTone';
// import SmartToyTwoToneIcon from '@mui/icons-material/SmartToyTwoTone';
import ApartmentTwoToneIcon from '@mui/icons-material/ApartmentTwoTone';


import { gradovi, regioni, adrese, izvestaji } from '../../../../db/db';


let menuItems = [
  {
    items: [
      {
        name: 'Dashboard',
        icon: DashboardIcon,
        link: '/dashboards',
      }
    ]
  },
];

let location = {
  heading: 'Locations',
  link: '/gradovi',
  items: [],
}

if(gradovi && gradovi.length > 0){
  for(let g = 0; g < gradovi.length; g++){
    let gradItem = {
      name: gradovi[g].naziv,
      icon: ApartmentTwoToneIcon,
      link: gradovi[g].path,
      can: true,
    };

    let graditems = [];
    let regioniList = regioni.filter(x => x.gradId == gradovi[g].id);
    if(regioniList && regioniList.length > 0){
      for(let r = 0; r < regioniList.length; r++){
        let regionItem = {
          name: regioniList[r].naziv,
          link: gradovi[g].path + regioniList[r].path,
          can: true,
        };
        
        let regioniItems = [];
        let adreseList = adrese.filter(x => (x.gradId == gradovi[g].id) && (x.regionId == regioniList[r].id));
        if(adreseList && adreseList.length > 0){
          for(let a = 0; a < adreseList.length; a++){
            let adresaItem = {
              name: adreseList[a].naziv,
              link: gradovi[g].path + regioniList[r].path + adreseList[a].path,
              can: true,
            };

            let izvestajiItems = [];
            for(let i = 0; i < izvestaji.length; i++){
              izvestajiItems.push({
                name: izvestaji[i].naziv,
                link: 'izvestaj' + gradovi[g].path + regioniList[r].path + adreseList[a].path + izvestaji[i].path,
                can: false,
              });
            }
            adresaItem['items'] = izvestajiItems;
            
            regioniItems.push(adresaItem);
          }
        }
        regionItem['items'] = regioniItems;

        graditems.push(regionItem);
      }
    } else {
      let adreseList = adrese.filter(x => x.gradId == gradovi[g].id);
      if(adreseList && adreseList.length > 0){
        for(let a = 0; a < adreseList.length; a++){
          let adresaItem = {
            name: adreseList[a].naziv,
            link: gradovi[g].path + adreseList[a].path,
            can: true,
          };

          let izvestajiItems = [];
          for(let i = 0; i < izvestaji.length; i++){
            izvestajiItems.push({
              name: izvestaji[i].naziv,
              link: 'izvestaj' + gradovi[g].path + '/region/0' + adreseList[a].path + izvestaji[i].path,
              can: false,
            });
          }
          adresaItem['items'] = izvestajiItems;
          
          graditems.push(adresaItem);
        }
      }
    }
    gradItem['items'] = graditems;

    location.items.push(gradItem);
  }
}
menuItems.push(location);


let statistics = {
  heading: 'Statistics',
  items: [
    {
      name: 'Compare by locations',
      icon: AccountTreeTwoToneIcon,
      link: '/compare-by-locations'
    },
    {
      name: 'Compare by days',
      icon: AccountTreeTwoToneIcon,
      link: '/compare-by-days'
    },
    {
      name: 'Statistics by year',
      icon: AccountTreeTwoToneIcon,
      link: '/statistics-by-year'
    },
  ],
}
menuItems.push(statistics);


// const menuItems = [
//   {
//     items: [
//       {
//         name: 'Dashboard',
//         icon: AnalyticsTwoToneIcon,
//         link: '/dashboards',
//       }
//     ]
//   },

//   {
//     heading: 'Locations',
//     items: [
//       // {
//       //   name: 'Aleksandrovac',
//       //   icon: ApartmentTwoToneIcon,
//       //   link: '',
//       //   items: [
//       //     {
//       //       name: '29. Novembra bb',
//       //       link: '',
//       //       // badge: 'v3.0',
//       //       // badgeTooltip: 'Added in version 3.0'
//       //     },
//       //     // {
//       //     //   name: 'Accent header',
//       //     //   link: '/accent-header/dashboards',
//       //     //   badge: '',
//       //     //   badgeTooltip: 'Updated'
//       //     // },
//       //     // {
//       //     //   name: 'Accent sidebar',
//       //     //   link: '/accent-sidebar/dashboards'
//       //     // },
//       //     // {
//       //     //   name: 'Boxed sidebar',
//       //     //   link: '/boxed-sidebar/dashboards'
//       //     // },
//       //     // {
//       //     //   name: 'Collapsed sidebar',
//       //     //   link: '/collapsed-sidebar/dashboards'
//       //     // },
//       //     // {
//       //     //   name: 'Bottom navigation',
//       //     //   link: '/bottom-navigation/dashboards'
//       //     // },
//       //     // {
//       //     //   name: 'Top navigation',
//       //     //   link: '/top-navigation/dashboards'
//       //     // }
//       //   ]
//       // },
//       {
//         name: 'Aleksandrovac',
//         icon: ApartmentTwoToneIcon,
//         link: '/grad/1',
//         items: [
//           {
//             name: '29. Novembra bb',
//             link: '/grad/1/region/1',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/1/region/1/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/1/region/1/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/1/region/1/izvestaj/total',
//               }
//             ]
//           },
//           // {
//           //   name: 'File Manager',
//           //   link: 'applications/file-manager'
//           // },
//           // {
//           //   name: 'Jobs Platform',
//           //   link: 'applications/jobs-platform'
//           // },
//           // {
//           //   name: 'Mailbox',
//           //   link: 'applications/mailbox/inbox'
//           // },
//           // {
//           //   name: 'Messenger',
//           //   link: 'applications/messenger'
//           // },
//           // {
//           //   name: 'Projects Board',
//           //   link: 'applications/projects-board'
//           // }
//         ]
//       },
//       {
//         name: 'Brus',
//         icon: ApartmentTwoToneIcon,
//         link: '/grad/2',
//         items: [
//           {
//             name: 'Kralja Petra I 42',
//             link: '/grad/2/region/1',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/2/region/1/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/2/region/1/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/2/region/1/izvestaj/total',
//               }
//             ]
//           },
//           // {
//           //   name: 'File Manager',
//           //   link: 'applications/file-manager'
//           // },
//           // {
//           //   name: 'Jobs Platform',
//           //   link: 'applications/jobs-platform'
//           // },
//           // {
//           //   name: 'Mailbox',
//           //   link: 'applications/mailbox/inbox'
//           // },
//           // {
//           //   name: 'Messenger',
//           //   link: 'applications/messenger'
//           // },
//           // {
//           //   name: 'Projects Board',
//           //   link: 'applications/projects-board'
//           // }
//         ]
//       },      
//       {
//         name: 'Beograd',
//         icon: ApartmentTwoToneIcon,
//         // badge: '',
//         // badgeTooltip: 'Tokyo contains over 250 data display blocks',
//         link: '/grad/3',
//         items: [
//           {
//             name: 'Vračar',
//             link: '/grad/3/region/1',
//             items: [
//               {
//                 name: 'Bulevar Kralja Aleksandra 130',
//                 link: '/grad/3/region/1/adresa/1',
//                 items: [
//                   {
//                     name: 'IN',
//                     link: '/grad/3/region/1/adresa/1/izvestaj/in',
//                   },
//                   {
//                     name: 'OUT',
//                     link: '/grad/3/region/1/adresa/1/izvestaj/out',
//                   },
//                   {
//                     name: 'TOTAL',
//                     link: '/grad/3/region/1/adresa/1/izvestaj/total',
//                   }
//                 ]
//               }
//             ]
//           },
//           {
//             name: 'Borča, Palilula',
//             link: '/grad/3/region/2',
//             items: [
//               {
//                 name: 'Zrenjaninski put 155',
//                 link: '/grad/3/region/2/adresa/1',
//                 items: [
//                   {
//                     name: 'IN',
//                     link: '/grad/3/region/2/adresa/1/izvestaj/in',
//                   },
//                   {
//                     name: 'OUT',
//                     link: '/grad/3/region/2/adresa/1/izvestaj/out',
//                   },
//                   {
//                     name: 'TOTAL',
//                     link: '/grad/3/region/2/adresa/1/izvestaj/total',
//                   }
//                 ]
//               }
//             ]
//           },
//           // {
//           //   name: 'Charts small',
//           //   link: 'blocks/charts-small'
//           // },
//           // {
//           //   name: 'Composed cards',
//           //   link: 'blocks/composed-cards'
//           // },
//           // {
//           //   name: 'Grids',
//           //   link: 'blocks/grids'
//           // },
//           // {
//           //   name: 'Icon cards',
//           //   link: 'blocks/icon-cards'
//           // },
//           // {
//           //   name: 'Image cards',
//           //   link: 'blocks/image-cards'
//           // },
//           // {
//           //   name: 'Lists large',
//           //   link: 'blocks/lists-large'
//           // },
//           // {
//           //   name: 'Lists small',
//           //   link: 'blocks/lists-small'
//           // },
//           // {
//           //   name: 'Navigation',
//           //   link: 'blocks/navigation'
//           // },
//           // {
//           //   name: 'Profile cards',
//           //   link: 'blocks/profile-cards'
//           // },
//           // {
//           //   name: 'Progress circular',
//           //   link: 'blocks/progress-circular'
//           // },
//           // {
//           //   name: 'Progress horizontal',
//           //   link: 'blocks/progress-horizontal'
//           // },
//           // {
//           //   name: 'Sparklines large',
//           //   link: 'blocks/sparklines-large'
//           // },
//           // {
//           //   name: 'Sparklines small',
//           //   link: 'blocks/sparklines-small'
//           // },
//           // {
//           //   name: 'Statistics',
//           //   link: 'blocks/statistics'
//           // }
//         ]
//       },
//       {
//         name: 'Kraljevo',
//         icon: ApartmentTwoToneIcon,
//         link: '/grad/4',
//         items: [
//           {
//             name: 'Dimitrija Tucovića 40',
//             link: '/grad/4/region/1',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/4/region/1/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/4/region/1/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/4/region/1/izvestaj/total',
//               }
//             ]
//           },
//           // {
//           //   name: 'File Manager',
//           //   link: 'applications/file-manager'
//           // },
//           // {
//           //   name: 'Jobs Platform',
//           //   link: 'applications/jobs-platform'
//           // },
//           // {
//           //   name: 'Mailbox',
//           //   link: 'applications/mailbox/inbox'
//           // },
//           // {
//           //   name: 'Messenger',
//           //   link: 'applications/messenger'
//           // },
//           // {
//           //   name: 'Projects Board',
//           //   link: 'applications/projects-board'
//           // }
//         ]
//       },
//       {
//         name: 'Kruševac',
//         icon: ApartmentTwoToneIcon,
//         link: '/grad/5',
//         items: [
//           {
//             name: 'Birčaninova 10',
//             link: '/grad/5/region/1',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/5/region/1/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/5/region/1/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/5/region/1/izvestaj/total',
//               }
//             ]
//           },
//           {
//             name: 'Vidovdanska 233',
//             link: '/grad/5/region/2',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/5/region/2/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/5/region/2/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/5/region/2/izvestaj/total',
//               }
//             ]
//           },
//           {
//             name: 'Rasinska 101',
//             link: '/grad/5/region/3',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/5/region/3/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/5/region/3/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/5/region/3/izvestaj/total',
//               }
//             ]
//           },
//           {
//             name: 'Trg Despota Stefana 30',
//             link: '/grad/5/region/4',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/5/region/4/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/5/region/4/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/5/region/4/izvestaj/total',
//               }
//             ]
//           },
//           {
//             name: 'Čolak Antina 17',
//             link: '/grad/5/region/5',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/5/region/5/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/5/region/5/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/5/region/5/izvestaj/total',
//               }
//             ]
//           },
//           // {
//           //   name: 'Projects Board',
//           //   link: 'applications/projects-board'
//           // }
//         ]
//       },
//       {
//         name: 'Paraćin',
//         icon: ApartmentTwoToneIcon,
//         link: '/grad/6',
//         items: [
//           {
//             name: 'Vojvode Mišića 5',
//             link: '/grad/6/region/1',
//             items: [
//               {
//                 name: 'IN',
//                 link: '/grad/6/region/1/izvestaj/in',
//               },
//               {
//                 name: 'OUT',
//                 link: '/grad/6/region/1/izvestaj/out',
//               },
//               {
//                 name: 'TOTAL',
//                 link: '/grad/6/region/1/izvestaj/total',
//               }
//             ]
//           },
//           // {
//           //   name: 'File Manager',
//           //   link: 'applications/file-manager'
//           // },
//           // {
//           //   name: 'Jobs Platform',
//           //   link: 'applications/jobs-platform'
//           // },
//           // {
//           //   name: 'Mailbox',
//           //   link: 'applications/mailbox/inbox'
//           // },
//           // {
//           //   name: 'Messenger',
//           //   link: 'applications/messenger'
//           // },
//           // {
//           //   name: 'Projects Board',
//           //   link: 'applications/projects-board'
//           // }
//         ]
//       }
//     ]
//   },
//   // {
//   //   heading: 'Management',
//   //   items: [
//   //     {
//   //       name: 'Users',
//   //       icon: ApartmentTwoToneIcon,
//   //       link: '/extended-sidebar/management/users',
//   //       items: [
//   //         {
//   //           name: 'List',
//   //           link: 'management/users/list'
//   //         },
//   //         {
//   //           name: 'User Profile',
//   //           link: 'management/users/single'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       name: 'Projects',
//   //       icon: AccountTreeTwoToneIcon,
//   //       link: '/extended-sidebar/management/projects/list'
//   //     },
//   //     {
//   //       name: 'Commerce',
//   //       icon: StorefrontTwoToneIcon,
//   //       link: '/extended-sidebar/management/commerce',
//   //       items: [
//   //         {
//   //           name: 'Shop',
//   //           link: 'management/commerce/shop'
//   //         },
//   //         {
//   //           name: 'List',
//   //           link: 'management/commerce/products/list'
//   //         },
//   //         {
//   //           name: 'Details',
//   //           link: 'management/commerce/products/single/1'
//   //         },
//   //         {
//   //           name: 'Create',
//   //           link: 'management/commerce/products/create'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       name: 'Invoices',
//   //       icon: ReceiptTwoToneIcon,
//   //       link: '/extended-sidebar/management/invoices',
//   //       items: [
//   //         {
//   //           name: 'List',
//   //           link: 'management/invoices/list'
//   //         },
//   //         {
//   //           name: 'Details',
//   //           link: 'management/invoices/single'
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // },
//   // {
//   //   heading: 'Extra Pages',
//   //   items: [
//   //     {
//   //       name: 'Auth Pages',
//   //       icon: VpnKeyTwoToneIcon,
//   //       link: '/auth',
//   //       items: [
//   //         {
//   //           name: 'Login',
//   //           items: [
//   //             {
//   //               name: 'Basic',
//   //               link: '/account/login-basic'
//   //             },
//   //             {
//   //               name: 'Cover',
//   //               link: '/account/login-cover'
//   //             }
//   //           ]
//   //         },
//   //         {
//   //           name: 'Register',
//   //           items: [
//   //             {
//   //               name: 'Basic',
//   //               link: '/account/register-basic'
//   //             },
//   //             {
//   //               name: 'Cover',
//   //               link: '/account/register-cover'
//   //             },
//   //             {
//   //               name: 'Wizard',
//   //               link: '/account/register-wizard'
//   //             }
//   //           ]
//   //         },
//   //         {
//   //           name: 'Recover Password',
//   //           link: '/account/recover-password'
//   //         }
//   //       ]
//   //     },
//   //     {
//   //       name: 'Status',
//   //       icon: ErrorTwoToneIcon,
//   //       link: '/status',
//   //       items: [
//   //         {
//   //           name: 'Error 404',
//   //           link: '/status/404'
//   //         },
//   //         {
//   //           name: 'Error 500',
//   //           link: '/status/500'
//   //         },
//   //         {
//   //           name: 'Maintenance',
//   //           link: '/status/maintenance'
//   //         },
//   //         {
//   //           name: 'Coming Soon',
//   //           link: '/status/coming-soon'
//   //         }
//   //       ]
//   //     }
//   //   ]
//   // },
//   // {
//   //   heading: 'Foundation',
//   //   items: [
//   //     {
//   //       name: 'Overview',
//   //       link: '/overview',
//   //       icon: DesignServicesTwoToneIcon
//   //     },
//   //     {
//   //       name: 'Documentation',
//   //       icon: SupportTwoToneIcon,
//   //       link: '/docs'
//   //     }
//   //   ]
//   // }
// ];

export default menuItems;
