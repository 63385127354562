/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

import Utils from '../utils/Utils';
import { gradovi } from '../db/db';


function NewReducer() {
    const name = 'testSlice';


    const initialState = {
        isLoading: false,
        grad: null,
    };


    const reducers = {
        start: (state) => {
            state.isLoading = true;
            state.grad = null;
        },
        finish: (state, action) => {
            let grad = action.payload;
        
            state.grad = grad;
            state.isLoading = false;
        },
     };


    const apis = {
        getDetails: (gradId = null) => async (dispatch) => {
            dispatch(actions.start());
            
            gradId = (gradId == null) ? Utils.getRnd(1, 6) : gradId;

            await setTimeout(() => {
                let filtriraniGradovi = gradovi.filter(x => x.id == gradId);
                let grad = (filtriraniGradovi && filtriraniGradovi.length > 0) ? filtriraniGradovi[0] : null;

                dispatch(actions.finish(grad));
            }, 600);
        },
    };


    const { reducer, actions } = createSlice({
        name,
        initialState,
        reducers,
    });


    return {
        reducer,
        ...actions,
        ...apis,
    };
}


export default NewReducer();