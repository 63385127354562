import slika1 from '../content/dashboards/Fitness/images/1.png';
import slika2 from '../content/dashboards/Fitness/images/2.png';
import slika3 from '../content/dashboards/Fitness/images/3.png';
import slika4 from '../content/dashboards/Fitness/images/4.png';
import slika5 from '../content/dashboards/Fitness/images/5.png';
import slika6 from '../content/dashboards/Fitness/images/6.png';
import slika7 from '../content/dashboards/Fitness/images/7.png';
import slika8 from '../content/dashboards/Fitness/images/8.png';
import slika9 from '../content/dashboards/Fitness/images/9.png';
import slika10 from '../content/dashboards/Fitness/images/10.png';
import slika11 from '../content/dashboards/Fitness/images/11.png';


export const gradovi = [
    {
        id: 1,
        naziv: 'Aleksandrovac',
        path: '/grad/1',
    },
    {
        id: 2,
        naziv: 'Brus',
        path: '/grad/2',
    },
    {
        id: 3,
        naziv: 'Beograd',
        path: '/grad/3',
    },
    {
        id: 4,
        naziv: 'Kraljevo',
        path: '/grad/4',
    },
    {
        id: 5,
        naziv: 'Kruševac',
        path: '/grad/5',
    },
    {
        id: 6,
        naziv: 'Paraćin',
        path: '/grad/6',
    },
]

export const regioni = [
    {
        id: 1,
        naziv: 'Vračar',
        path: '/region/1',
        gradId: 3,
    },
    {
        id: 2,
        naziv: 'Borča, Palilula',
        path: '/region/2',
        gradId: 3,
    },
]


export const adrese = [
    {
        id: 1,
        naziv: '29. Novembra bb',
        path: '/adresa/1',
        gradId: 1,
        regionId: null,
        slika: slika1,
    },
    {
        id: 2,
        naziv: 'Kralja Petra I 42',
        path: '/adresa/2',
        gradId: 2,
        regionId: null,
        slika: slika2,
    },
    {
        id: 3,
        naziv: 'Bulevar Kralja Aleksandra 130',
        path: '/adresa/3',
        gradId: 3,
        regionId: 1,
        slika: slika3,
    },
    {
        id: 4,
        naziv: 'Zrenjaninski put 155',
        path: '/adresa/4',
        gradId: 3,
        regionId: 2,
        slika: slika4,
    },
    {
        id: 5,
        naziv: 'Dimitrija Tucovića 40',
        path: '/adresa/5',
        gradId: 4,
        regionId: null,
        slika: slika5,
    },
    {
        id: 6,
        naziv: 'Birčaninova 10',
        path: '/adresa/6',
        gradId: 5,
        regionId: null,
        slika: slika6,
    },
    {
        id: 7,
        naziv: 'Vidovdanska 233',
        path: '/adresa/7',
        gradId: 5,
        regionId: null,
        slika: slika7,
    },
    {
        id: 8,
        naziv: 'Rasinska 101',
        path: '/adresa/8',
        gradId: 5,
        regionId: null,
        slika: slika8,
    },
    {
        id: 9,
        naziv: 'Trg Despota Stefana 30',
        path: '/adresa/9',
        gradId: 5,
        regionId: null,
        slika: slika9,
    },
    {
        id: 10,
        naziv: 'Čolak Antina 17',
        path: '/adresa/10',
        gradId: 5,
        regionId: null,
        slika: slika10,
    },
    {
        id: 11,
        naziv: 'Vojvode Mišića 5',
        path: '/adresa/11',
        gradId: 6,
        regionId: null,
        slika: slika11,
    },
]


export const izvestaji = [
    {
        id: 1,
        path: '/izvestaj/in/1',
        naziv: 'In',
    },
    {
        id: 2,
        path: '/izvestaj/out/2',
        naziv: 'Out',
    },
    {
        id: 3,
        path: '/izvestaj/total/3',
        naziv: 'Total',
    },
]