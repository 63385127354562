/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createSlice } from "@reduxjs/toolkit";

import Utils from '../utils/Utils';
import { DateFilter } from '../utils/Enums';


function NewReducer() {
    const name = 'yearFilter';


    const initialState = {
        isLoading: false,
        selectedDate: new Date(),
    };


    const reducers = {
        start: (state, action) => {
            let data = action.payload;
        
            state.isLoading = true;
            state.selectedDate = data;
        },
        finish: (state, action) => {
            state.isLoading = false;
        },
    };


    const apis = {
        setSelectedDate: (date = null) => async (dispatch) => {
            dispatch(actions.start(date));
            
            await setTimeout(() => {
                dispatch(actions.finish());
            }, 1500);
        },
    };


    const { reducer, actions } = createSlice({
        name,
        initialState,
        reducers,
    });


    return {
        reducer,
        ...actions,
        ...apis,
    };
}


export default NewReducer();