/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import numeral from 'numeral';
import { DateFilter } from './Enums';


const Utils = {

    getByParam: (arr = [], searchValue = null, returnField = 'naziv', searchBy = 'id') => {
        if(searchValue){
            let items = (arr && arr.length > 0) ? arr.filter(x => x[searchBy] == searchValue) : [];
            return (items && items.length > 0) ? items[0][returnField] : '';
        } 
        return '';
    },

    getRnd: (min, max) => {
        try {
            return  Math.floor(Math.random() * (max - min + 1)) + min;
        } catch(e){
            return 0;
        }
    },
    
    formatPrice: (price = 0) => {
        try {
            return numeral(price).format('0,0.00');
        } catch(e){
            return price;
        }
    },

    getGraphLabels: (selectedValue = 0) => {
        let labels = [];

        if(selectedValue === DateFilter.TODAY){
            labels = [ 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ];

        } else if(selectedValue === DateFilter.THIS_MONTH){
            labels = [ 'First week', 'Second week', 'Third week', 'Fourth week' ];

        } else if(selectedValue === DateFilter.THIS_YEAR){
            labels = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];
        }

        return labels;
    },

    getMainGraphLabels: (selectedValue = 0) => {
        const currentDate = new Date();
        let labels = [];

        if(selectedValue === DateFilter.TODAY){
            for (let i = 0; i < 5; i++) {
                const year = currentDate.getFullYear() - 4 + i;
                const formattedDate = `${currentDate.getDate()}.${currentDate.getMonth() + 1}.${year}`;
                labels.push(formattedDate);
              }
        } else if(selectedValue === DateFilter.THIS_MONTH){
            for (let i = 3; i >= 0; i--) {
                const year = currentDate.getFullYear() - i;
                const formattedDate = `${currentDate.toLocaleString('default', { month: 'long' })}.${year}`;
                labels.push(formattedDate);
              }

        } else if(selectedValue === DateFilter.THIS_YEAR){
            for (let i = 5; i >= 0; i--) {
                const year = currentDate.getFullYear() - i;
                labels.push(year.toString());
              }
        }

        return labels;
    },

    getGraphValues: (selectedValue = 0) => {
        let values = [];

        if(selectedValue === DateFilter.TODAY){
            for(let i = 0; i < 5; i++){
                values.push(Utils.getRnd(10000, 50000));
            }

        } else if(selectedValue === DateFilter.THIS_MONTH){
            for(let i = 0; i < 4; i++){
                values.push(Utils.getRnd(100000, 500000));
            }

        } else if(selectedValue === DateFilter.THIS_YEAR){
            for(let i = 0; i < 6; i++){
                values.push(Utils.getRnd(500000, 1000000));
            }
        }

        return values;
    },
    getManufacturerGraphValues: (selectedValue = 0) => {
        let values = [];

            for(let i = 0; i < 7; i++){
                values.push(Utils.getRnd(10000, 50000));
            }

        return values;
    },
}


export default Utils;